<head>
  <link rel="stylesheet" href="/assets/css/intlTelInput.css" async />
</head>

<div class="row">
  <div class="col-md-12">
    <div class="login_page">
      <div id="recaptcha-container"></div>
      <section class="register">
        <div class="modal-header close_btn_align">
          <button type="button" class="btn-close btn-close-black" data-bs-dismiss="modal"
            (click)="closeModal()"></button>
        </div>
        <div class="loader-overlay" *ngIf="isLoading">
          <div class="spinner">
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="position-relative" *ngIf="!isSignInwithEmail && !validatePhoneNumber && !isResendOTP">
          <div class="box">
            <div class="justify-content-center mb-4 pb-1" *ngIf="!isForgotPassword">
              <div class="user-show-head">Login With askIITians</div>
            </div>
            <form class="">
              <div class="sign_up_hide">
                <!-- class="mt-3" -->
                <div *ngIf="!isForgotPassword">
                  <div class="col-md-12 text-center">
                    <span id="login-with-email-text"><a (click)="loginwithEmail()" class="login_sign_link color-white"
                        id="emailText"><img class="email-Icon-brand-svg" loading="lazy"
                          src="/assets/images/email-Icon-brand.svg" alt="Email-Icon-brand" /> Sign in with
                        Email</a></span>
                  </div>
                </div>
                <div>
                  <div class="google-sign-in" *ngIf="!isForgotPassword">
                    <div class="text-center">
                      <app-google-sso-login [clientId]="googleClientId"
                        (onSigninSuccess)="onGoogleSigninSuccess($event)"></app-google-sso-login>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="position-relative" *ngIf="isSignInwithEmail && !validatePhoneNumber && !isResendOTP">
          <form [formGroup]="loginWithEmailForm" class="px-3">
            <div class="sign_up_hide">
              <div>
                <!-- Email input -->
                <div class="form-group">
                  <label for="name-user" *ngIf="!isForgotPassword" class="lable-space">Email</label>
                  <label for="name-user" *ngIf="isForgotPassword" class="lable-space">Enter Your Email Address:</label>
                  <input type="email" id="names-user" placeholder="Type Email Here..." required class="form-control"
                    name="email" formControlName="Email" (keyup)="keyUpEvent($event)"
                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                  <div *ngIf="e['Email'].touched && e['Email'].invalid" class="alert alert-danger">
                    <div *ngIf="e['Email'].errors && e['Email'].errors['required']">Email is required.</div>
                    <div *ngIf="e['Email'].errors && e['Email'].errors['pattern']" class="text-danger">
                      Please provide a valid email address
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div class="form-group">
                  <label for="passowrd" class="lable-space" *ngIf="isUserExist && !createPassword">Password</label>
                  <label for="passowrd" class="lable-space" *ngIf="!isUserExist && createPassword">Create
                    Password</label>
                  <input *ngIf="showPasswordField" type="password" class="form-control"
                    placeholder="Type Password Here..." name="" id="email-user" formControlName="Password" />
                  <div *ngIf="e['Password'].touched && e['Password'].invalid && isValidPassword"
                    class="alert alert-danger">
                    <div *ngIf="e['Password'].errors && e['Password'].errors['required']">Please Enter Password</div>
                  </div>
                  <div *ngIf="!isValidPassword" class="alert alert-danger">
                    <div>Please Enter valid Password</div>
                  </div>
                </div>
              </div>
              <div class="mt-3" *ngIf="isUserExist">
                <div class="col-md-6 text-center">
                  <span id="login-with-email-text"><a class="color-white" (click)="forgotPassword()">Forgot
                      Password?</a></span>
                </div>
              </div>
              <div class="login_hide extraButton" *ngIf="!isForgotPassword">
                <div class="mt-4 text-center login_submit">
                  <button type="submit" class="btn login_btn" (click)="VerifyMobileNumber()">NEXT</button>
                </div>
              </div>
              <div class="login_hide extraButton">
                <div class="mt-4 text-center login_submit" *ngIf="isForgotPassword">
                  <button type="submit" [disabled]="!e['Email'].valid" (click)="getPassword()" class="btn login_btn">Get
                    Password</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="position-relative" *ngIf="validatePhoneNumber ||isResendOTP">
          <form [formGroup]="mobileVerificationForm" class="px-3">
            <div class="form-group">
              <label for="name-user" class="lable-space">Phone Number</label>
              <app-country-picker name="PhoneNo" formControlName="PhoneNo"></app-country-picker>
              <div *ngIf="f['PhoneNo'].touched && f['PhoneNo'].invalid" class="alert alert-danger">
                <div *ngIf="f['PhoneNo'].errors && f['PhoneNo'].errors?.['required']">Phone Number is required.
                </div>
                <div *ngIf="f['PhoneNo'].errors && f['PhoneNo'].errors?.['invalidPhoneNumber']"
                  class="text-danger">Invalid Phone Number.</div>
              </div>
            </div>
            <div class="login_hide extraButton">
              <div class="mt-4 text-center login_submit">
                <button type="submit" class="btn login_btn" (click)="VerifyOtp()" [disabled]="mobileVerificationForm.invalid">NEXT</button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>

  </div>