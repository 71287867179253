import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CacheService } from './services/cache.service';
import { UserModel } from './Models/UserModel';
import { StorageService } from './services/storage.service';
import { StorageKey, StorageType } from './storage-key';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { Askiitians } from './Models/AuthModel';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    location.reload();
  }
  isLoggedIn: boolean = false;
  userDetails!: UserModel;
  isServer: any;
  isBrowser: any;
  cssScript: any[] = [
    'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css',
    'https://cdnjs.cloudflare.com/ajax/libs/libphonenumber-js/1.10.6/libphonenumber-js.min.js'
  ]
  isContentLoaded: boolean = false;

  constructor(
    private cacheService: CacheService,
    private storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private dom: any,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.isServer = isPlatformServer(this.platformId);
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.isContentLoaded = !!this.activatedRoute.snapshot.firstChild;
    });
  }

  ngOnInit() {
    if (this.storage.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL) == 'true') {
      this.isLoggedIn = true;
      this.userDetails = JSON.parse(this.storage.getItem(StorageKey.USER, StorageType.LOCAL)) as UserModel;
      this.cacheService.onUserDetailsChange(this.userDetails);
    }
    else if (this.storage.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL) == 'false') {
      this.isLoggedIn = false;
      this.userDetails = JSON.parse(this.storage.getItem(StorageKey.USER, StorageType.LOCAL)) as UserModel;
      this.cacheService.onUserDetailsChange(null);
    }
    this.cacheService._userDetails.subscribe((res) => {
      if (res != null) {
        this.isLoggedIn = true;
        this.userDetails = res;
      }
    });
    this.loadCSS(this.cssScript);
  }
  loadCSS(csslist: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (Array.isArray(csslist)) {
        for (let n = 0; n < csslist.length; n++) {
          const file = csslist[n];
          const isCSS = file.endsWith('.css');
          const isJS = file.endsWith('.js');
          if (isCSS) {
            let link = this.dom.createElement('link');
            link.setAttribute('rel', 'stylesheet');
            this.dom.head.appendChild(link);
            link.setAttribute('href', file);
          } else if (isJS) {
            let script = this.dom.createElement('script');
            this.dom.head.appendChild(script);
            script.setAttribute('src', file);
          }
        }
      }
    }
  }
}
