<div class="modal-xl " id="openRegister" *ngIf="show">
    <div class="modal-content modal_content_register">

        <!-- Modal Header -->
        <div class="modal-header close_btn_align">
            <h4 class="modal-title"></h4>
            <button type="button" class="btn-close  btn-close-white" data-bs-dismiss="modal"
                (click)="closeModal()"></button>
        </div>

        <!-- Modal body -->
        <div class="modal-body rounded-1">
            <div class="container register_popup_container rounded-1">
                <h1 *ngIf="!isShow">
                    Start Your Journey to Success NOW, To unlock content start your 3 day free trial now for
                    free
                </h1>

                <h1 *ngIf="isShow">
                    Enjoy 3 Days Free Trial
                </h1>
                <div class="five_types_register_box">
                    <div class="circle_img_text_content_container">
                        <div class="circle_img_container color_red">
                            <img loading="lazy" src="/assets/img/live.png" alt="live" />
                        </div>
                        <p title="Full Live Access">Full Live <br /> Access</p>
                    </div>

                    <div class="circle_img_text_content_container">
                        <div class="circle_img_container color_green">
                            <img loading="lazy" src="/assets/img/material.png" alt="material" />
                        </div>
                        <p title="Full Live Access">Study <br /> Material</p>
                    </div>
                    
                    <div class="circle_img_text_content_container">
                        <div class="circle_img_container color_dark_green">
                            <img loading="lazy" src="/assets/img/mindmap.png" alt="mindmap" />
                        </div>
                        <p title="Full Live Access">Mind <br /> Maps</p>
                    </div>

                    <div class="circle_img_text_content_container">
                        <div class="circle_img_container color_orange">
                            <img loading="lazy" src="/assets/img/dubts.png" alt="dubts" />
                        </div>
                        <p title="Full Live Access">Live Doubts <br /> Solving</p>
                    </div>

                    <div class="circle_img_text_content_container">
                        <div class="circle_img_container color_grey">
                            <img loading="lazy" src="/assets/img/assignments.png" alt="assignments" />
                        </div>
                        <p title="Full Live Access">Daily Class <br /> Assignments</p>
                    </div>
                </div>

                <a *ngIf="!isShow" class="btn rounded-0 " (click)="getPackageDetails()">START FREE TRIAL</a>

                <a *ngIf="isShow" class="btn rounded-0 " (click)="GoToStudyPod()">EXPLORE STUDYPOD</a>
            </div>
        </div>
    </div>

</div>