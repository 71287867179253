<app-side-nav class="navbar_hide" *ngIf="isLogin" (sideNav)="manageSideNav($event)"></app-side-nav>
<ng-container *ngIf="currentRoute != '/sat'">
    <header class="bg-white header123 {{scrollingClass}}" [ngClass]="{'header-auto-hide' : !toggleMobileMenu}"
        *ngIf="currentRoute != '/juniorHacker'" style="background:#FFFFFF ;">
        <div class="contact">
            <div class="container">
                <div class="email">
                    <a href="mailto:help@askiitians.com">
                        <i class="header fa fa-envelope-o"></i> help&#64;askiitians.com
                    </a>
                </div>

                <div class="mobile">
                    <a href="tel:1800-150-456-789">
                        <i class="header fa fa-phone"></i> 1800-150-456-789
                    </a>
                </div>
            </div>
        </div>

        <!-- NAV START -->
        <nav>
            <div class="container">
                <div class="d-flex justify-content-between">
                    <div class="menu d-flex align-items-center">
                        <div class="lines1" *ngIf="ifHome" (click)="expandMenu = !expandMenu ; expandUserMenu()">
                            <div [ngClass]="{'first-line' : expandMenu}" class="line"></div>
                            <div [ngClass]="{'mid-line' : expandMenu}" class="line hide-line"></div>
                            <div [ngClass]="{'last-line' : expandMenu}" class="line"></div>
                        </div>

                        <div class="lines" (click)="toggleMobileMenu = !toggleMobileMenu">
                            <div [ngClass]="{'first-line' : toggleMobileMenu}" class="line"></div>
                            <div [ngClass]="{'mid-line' : toggleMobileMenu}" class="line hide-line"></div>
                            <div [ngClass]="{'last-line' : toggleMobileMenu}" class="line"></div>
                        </div>
                        <a href=""><img src="/assets/images/Logo.webp" alt="logo" width="143px"
                                height="40px"></a>
                    </div>
                    <div class="my-instruction" [ngClass]="{'my-instruction-right' : toggleMobileMenu}">
                        <div class="click-items" *ngIf="isLogin">
                            <div class="menu-home active-color" [ngClass]="{'active-color': mobileActiveMenu == 'menu'}"
                                (click)="mobileActiveMenu = 'menu'">
                                MENU
                            </div>
                            <div class="Courses-home" [ngClass]="{'active-color': mobileActiveMenu == 'myMenu'}"
                                (click)="mobileActiveMenu = 'myMenu'" *ngIf="isLogin">
                                My Menu
                            </div>
                        </div>


                        <div class="Courses-home-2" *ngIf="mobileActiveMenu == 'myMenu'"
                            [ngClass]="{'active-item': mobileActiveMenu == 'myMenu'}">
                            <app-side-nav *ngIf="isLogin" [childData]="isVerticalLayout" [hideSideNav]="hideSideNav"
                                (sendDataEvent)="receiveDataFromChild($event)" class="active"></app-side-nav>
                        </div>

                        <!-- header main menu start -->
                        <div class="navbar-mobile" [ngClass]="{'active-item-menu-show': mobileActiveMenu == 'myMenu'}">
                            <nav class="navbar navbar-expand-lg navbar-expand-md navbar-light mb-0">
                                <div class="container-fluid topmenu">

                                    <div class="collapse navbar-collapse mobile-navbar-collapse"
                                        id="navbarSupportedContent">
                                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                            <li class="nav-item">
                                                <a routerLink="/package/"
                                                    [ngClass]="{'active-hearder-tab': dekstopActiveMenu == 'package'}"
                                                    ariaCurrentWhenActive="page"
                                                    (click)="toggleMobileMenu = false ; dekstopActiveMenu = 'package'; closeSideNav()"
                                                    class="nav-link">Courses<span class="new_course">Live</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" routerLink="/resource-page-component/"
                                                    [ngClass]="{'active-hearder-tab': dekstopActiveMenu == 'resource-page-component'}"
                                                    (click)="toggleMobileMenu = false ;dekstopActiveMenu = 'resource-page-component';closeSideNav()"
                                                    ariaCurrentWhenActive="page" >Resources</a>
                                            </li>
                                            <li class="nav-item dropdown arrow">
                                               <a class="nav-link" href="javascript:void(0);" role="button" 
                                                (click)=" over_exam(); closeSideNav()"
                                                [ngClass]="{'active-hearder-tab': dekstopActiveMenu == 'examInfo'}"
                                                (mouseenter)="onMouseEnterOnExamInfo()"
                                                >
                                                Exam Info
                                                <img loading="lazy" src="/assets/images/Icon/down-arrow.png"
                                                        width="10px" height="10px" alt="downarrow">
                                                </a>
                                                <ul class="dropdown-menu" *ngIf="show">
                                                    <li class="nav-item dropend" *ngFor="let item of menuItems">
                                                        <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button"
                                                            data-bs-toggle="dropdown" aria-expanded="false" (click)="toggleParentList()">
                                                            {{item.menuTitle}}
                                                        </a>
                                                        <ul class="dropdown-menu d1"
                                                            *ngIf="parentList">
                                                            <li class="nav-item dropend"
                                                                *ngFor="let childItem of item.child">
                                                                <ng-container
                                                                    *ngIf="childItem.child && childItem.child.length > 0">
                                                                    <a class="nav-link dropdown-toggle" role="button"
                                                                        data-bs-toggle="dropdown" aria-expanded="false"  (click)="toggleChildList()">
                                                                        {{childItem.menuTitle}}
                                                                    </a>
                                                                    <ul class="dropdown-menu" *ngIf="childList">
                                                                        <li *ngFor="let lastItem of childItem.child"
                                                                            (click)="onclick_exam_info()">
                                                                            <div>
                                                                                <a [routerLink]="[lastItem.url]"
                                                                                    class="dropdown-item"
                                                                                    (click)="toggleMobileMenu = false; dekstopActiveMenu = 'examInfo'">
                                                                                    {{lastItem.menuTitle}}

                                                                                </a>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </ng-container>
                                                                <ng-container *ngIf="!childItem.child">
                                                                    <div>
                                                                        <a [routerLink]="[childItem.url]"
                                                                            class="dropdown-item"
                                                                            (click)="toggleMobileMenu = false;dekstopActiveMenu = 'examInfo'"
                                                                            (click)="onclick_exam_info()">
                                                                            {{childItem.menuTitle}}
                                                                        </a>
                                                                    </div>
                                                                </ng-container>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" routerLink="/forums/"
                                                    [ngClass]="{'active-hearder-tab': dekstopActiveMenu == 'forums'}"
                                                    ariaCurrentWhenActive="page"
                                                    (click)="toggleMobileMenu = false ; dekstopActiveMenu ='forums';closeSideNav();">Forum</a>
                                            </li>
                                            <li class="nav-item dropdown arrow">
                                               
                                                 <a class="nav-link" href="javascript:void(0);" role="button" 
                                                (click)="over_notes(); closeSideNav()"
                                                (mouseenter)="onMouseEnterOnNotes()"
                                                [ngClass]="{'active-hearder-tab': dekstopActiveMenu == 'notes'}">
                                                Notes
                                                <img loading="lazy" src="/assets/images/Icon/down-arrow.png"
                                                        width="10px" height="10px" alt="downarrow">
                                                </a>
                                                <ul class="dropdown-menu" *ngIf="show">
                                                    <li class="nav-item dropend"
                                                        *ngFor="let noteitem of notesmenuItems">
                                                        <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button"
                                                            data-bs-toggle="dropdown" (click)="parentList = !parentList">
                                                            {{noteitem.menuTitle}}
                                                        </a>
                                                        <ul class="dropdown-menu" 
                                                            *ngIf="parentList">
                                                            <li class="nav-item dropend"
                                                                *ngFor="let notechildItem of noteitem.child">
                                                                <ng-container
                                                                    *ngIf="notechildItem.child && notechildItem.child.length > 0">
                                                                    <a class="nav-link dropdown-toggle" href="javascript:void(0);"
                                                                        role="button" data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                        (click)="toggleMobileMenu = false;dekstopActiveMenu = 'notes'">
                                                                        {{notechildItem.menuTitle}}
                                                                    </a>
                                                                    <ul class="dropdown-menu">
                                                                        <li
                                                                            *ngFor="let lastItem of notechildItem.child">
                                                                            <div>
                                                                                <a [routerLink]="lastItem.url"
                                                                                    (click)="toggleMobileMenu = false"
                                                                                    class="dropdown-item" href="javascript:void(0);"
                                                                                    (click)="toggleMobileMenu = false;dekstopActiveMenu = 'notes'">
                                                                                    {{lastItem.menuTitle}}
                                                                                </a>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </ng-container>
                                                                <ng-container *ngIf="!notechildItem.child">
                                                                    <div>
                                                                        <a [routerLink]="notechildItem.url"
                                                                            class="dropdown-item" href="javascript:void(0);"
                                                                            (click)="onclick_notes()"
                                                                            (click)="toggleMobileMenu = false;dekstopActiveMenu = 'notes'">
                                                                            {{notechildItem.menuTitle}}
                                                                        </a>
                                                                    </div>
                                                                </ng-container>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div class="d-flex align-items-center header_search_box">

                        <button class="btn1" type="submit" *ngIf="!isLogin" (click)="openlogin();">Login</button>
                        <div class="profileShows" *ngIf="isLogin">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" (click)="showUserMenu = !showUserMenu;closeSideNav();"
                                    type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <img loading="lazy" src="/assets/images/profile_avatar.png" width="50" height="50"
                                        class="header_user_icon" alt="tectangle">
                                </button>
                                <div class="dropdown-menu" *ngIf="showUserMenu" (click)="showUserMenu = false" id="user-dropdown-menu"
                                    aria-labelledby="dropdownMenuButton">
                                    <p class="user_name_tag">Welcome <span>{{userDetails?.name}}</span></p>
                                    <a ngbDropdownItem routerLink="/user/profile-component" routerLinkActive="active"
                                        ariaCurrentWhenActive="page">Profile</a>
                                    <a ngbDropdownItem routerLink="/contact-us.aspx">Contact Us</a>
                                    <!-- <a ngbDropdownItem href="#">Change Us</a> -->
                                    <a ngbDropdownItem (click)="logout()">Log Out</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>

    <nav class="navbar  navbar-fixed-top navbar-expand-lg navbar-light bg-white"
        style="box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);" *ngIf="currentRoute == '/juniorHacker'">
        <div class="container" style="display: block;"> <!-- <div class="navbar-header"> -->
            <a class="navbar-brand"><img loading="lazy" src="https://files.askiitians.com/static_content/Resources/images/jh-images/logo.png" class="header-logo"
                    alt="Logo" width="178px" height="51px"></a>
            <button type="button" class="navbar-toggle" data-bs-toggle="collapse" data-bs-target="#jhNavbar"
                aria-controls="jhNavbar" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fa fa-bars" aria-hidden="true"></i>
            </button>
            <!-- </div> -->
            <div class="collapse navbar-collapse" id="jhNavbar">
                <ul class="navbar-nav">
                    <li class="navbar-item"><a href="#why" class="nav-link">Why Us</a></li>
                    <li class="navbar-item"><a href="#benefits" class="nav-link">Benefits</a></li>
                    <li class="navbar-item"><a href="#courses" class="nav-link">Courses</a></li>
                    <li class="navbar-item"><a href="#work" class="nav-link">How It Works</a></li>
                    <li class="navbar-item" style="margin-top: 15px"><a class="juheader-cta tempUrl"
                            (click)="bookAFreeClass()">BOOK A FREE CLASS</a></li>
                </ul>
            </div>
        </div>
    </nav>
</ng-container>