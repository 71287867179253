import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientIPAddressService {

  constructor(private http: HttpClient) {}

  getClientIp() {
    // Use a free IP lookup service to get the client's IP address
    return this.http.get('http://ip-api.com/json').pipe(
      tap(response => console.log('API Response:', response)), // Log the entire response
      map((response: any) => ({
       response:response
      }))
    );
  }

}
