import { AbstractControl, ValidationErrors } from "@angular/forms";
import { CountryCodeModel } from "../Models/CountryCode.Model";

declare let libphonenumber: any;
export const phoneNumberValidator = (control: any): boolean => {
    if (!control) {
        return false;
    }
    const code = control.split('-')[0];
    const phoneNumber = control.split('-')[1];

    const country = CountryCodeModel.find(x => x.code === code);
    if (!country) {
        return false; // Country code not found
    }

    if (libphonenumber.isValidPhoneNumber(phoneNumber, country.country_code)) {
        return true;
    }
    else {
        return false;
    }
}

export const NumberValidator = (control: AbstractControl): ValidationErrors | null => {
    const pNo = control.value;
    if (!pNo.split('-')[1]) {
        return { required: true };
    }
    const code = pNo.split('-')[0];
    const phoneNumber = pNo.split('-')[1];

    const country = CountryCodeModel.find(x => x.code === code);
    if (!country) {
        return { invalidCountryCode: true }; // Country code not found
    }

    if (libphonenumber.isValidPhoneNumber(phoneNumber, country.country_code)) {
        return null;
    }
    else {
        return { invalidPhoneNumber: true };
    }
}