import { Inject, Injectable, OnInit, PLATFORM_ID } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { FreeDemoRegistrationComponent } from '../popupModel/free-demo-registration/free-demo-registration.component';
import { LoginComponent } from '../popupModel/login/login.component';
import { StorageService } from './storage.service';
import { StorageKey, StorageType } from '../storage-key';
import { isPlatformBrowser } from '@angular/common';
import { Location } from '@angular/common';
import { RegistrationComponent } from '../popupModel/registration/registration.component';
import { NotificationService } from './notification.service';
import { NotificationType } from '../enum/NotificationType';
import { contactUsService } from './contactUs.service';
import { ContactUs } from '../Models/ContactUsModel';
import { ValidationRegex as validationRegex } from 'src/app/enum/ValidationRegex';
import { SourceIdEnum } from '../Models/sourceIdEnumModel';
import { LoginWithEmailComponent } from '../popupModel/login-with-email/login-with-email.component';
import { BookAFreeDemoService } from './book-afree-demo.service';
import { Bookafreedemo } from '../Models/Bookafreedemo';
import { ProductService } from './product.service';
import { CacheService } from './cache.service';
import { ResourceVideoViewComponent } from '../popupModel/resource-video-view/resource-video-view.component';
import { CmsJsService } from './cmsJS.service';
declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class CMSNavigationService implements OnInit {
  eventControl: any;
  userDetails: any;
  UserId!: number;
  pdfPath!: string;
  scrollViewId!: any;
  setScrollViewId!: HTMLElement;
  ContactUs!: ContactUs;
  responseMessage!: string;
  bookFreeDemoData: Bookafreedemo = {};
  routerSubscription: any;
  eventView: any;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: any,
    private location: Location,
    private notificationService: NotificationService,
    private contactService: contactUsService,
    private bookFreeDemo: BookAFreeDemoService,
    private productService: ProductService,
    private cacheService: CacheService,
    private jsService: CmsJsService
  ) {}
  ngOnInit() {}

  goToNavigation(eventControl: any, userDetails: any) {
    this.getUserDetails();
    this.eventControl = eventControl;
    this.userDetails = userDetails;
    if (this.eventControl.srcElement.id == 'buyCourses') {
      if (this.eventControl.srcElement.id == 'buyCourses' && this.eventControl.srcElement.name == 'btnBuyNow') {
        const packageUrl = this.router.url;
        if (this.userDetails == undefined) {
          const modalRef = this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
          modalRef.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
            if (isUserLogged) {
              // this.UserId = userid;
              this.productService.getProductDetailsByUrl(packageUrl.split('/')[2]).subscribe((res: any) => {
                if (res != null) {
                  this.router.navigate(['payment-credit-debit-component/' + res.id]);
                } else {
                  this.router.navigate(['page-not-found'], {
                    skipLocationChange: true,
                  });
                }
              });
            }
          });
        } else {
          this.productService.getProductDetailsByUrl(packageUrl.split('/')[2]).subscribe((res: any) => {
            if (res != null) {
              this.router.navigate(['payment-credit-debit-component/' + res.id]);
            } else {
              this.router.navigate(['page-not-found'], {
                skipLocationChange: true,
              });
            }
          });
        }
      } else {
        if (this.userDetails == undefined) {
          const modalRef = this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
          modalRef.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
            if (isUserLogged) {
              // modalRef.componentInstance.userid.subscribe((userid: any) => {
              //   if (userid) {
                  // this.UserId = userid;
                  this.goToBuyPackage();
                // }
              // });
            }
          });
        } else {
          this.goToBuyPackage();
        }
      }
    } else if (this.eventControl.srcElement.id == 'viewOurResults') {
      this.router.navigateByUrl('ResultsPage.aspx');
    } else if (this.eventControl.srcElement.id == 'requestACallBack') {
      const modelRef = this.modalService.open(RegistrationComponent, { centered: true, backdrop: 'static' });
      // modelRef.componentInstance.sourceId = sourceId;
      modelRef.componentInstance.isShowCrossbtn = true;
      modelRef.componentInstance.isRegistered.subscribe((isRegistered: any) => {
        if (isRegistered == true) {
          this.cacheService.setIsUserLoggedIn(true);
          this.notificationService.notifyMessage(NotificationType.Success, `You're successfully logged in!`);
        } else {
          this.cacheService.setIsUserLoggedIn(true);
          this.notificationService.notifyMessage(NotificationType.Success, `You're Register Successfully!`);
        }
      });
    } else if (this.eventControl.target.dataset.target == '#userForm') {
      this.router.navigateByUrl('contact-us.aspx');
    } else if (this.eventControl.srcElement.id == 'viewOurTeacher') {
      this.router.navigateByUrl('academic-experts-profile.aspx');
    } else if (this.eventControl.srcElement.id == 'bookAFreeDemo') {
      const sourceId = SourceIdEnum.FreeDemo;
      const modalRef = this.modalService.open(FreeDemoRegistrationComponent, { centered: true, backdrop: 'static' });
      modalRef.componentInstance.sourceId = sourceId;
    } else if (this.eventControl.srcElement.className == 'btn btn-success book-now') {
      const sourceId = SourceIdEnum.NRI;
      const modelRef = this.modalService.open(RegistrationComponent, { centered: true, backdrop: 'static' });
      modelRef.componentInstance.sourceId = sourceId;
      modelRef.componentInstance.isShowCrossbtn = true;
    } else if (
      eventControl.srcElement.parentElement.getAttribute('data-src') != '' ||
      eventControl.srcElement.parentElement.getAttribute('data-src') != undefined
    ) {
      const imgUrl = eventControl.srcElement.src;
      const link = eventControl.srcElement.parentElement.getAttribute('data-src');
      if (imgUrl != null && imgUrl != undefined && link != null && link != undefined) {
        const modalRef = this.modalService.open(ResourceVideoViewComponent, {
          size: 'xl',
          centered: true,
          backdrop: 'static',
        });
        modalRef.componentInstance.ImageURL = imgUrl;
        modalRef.componentInstance.Link = link;
        modalRef.componentInstance.isPackageVideo = true;
      }
    } else {
      const collapseTwo = document.getElementById('collapseTwo');
      if (collapseTwo) {
        collapseTwo.style.display = 'block';
      }
    }
  }

  goToBuyPackage() {
    if (this.eventControl.srcElement.name == 'silver') {
      let packageid = this.eventControl.srcElement.value;
      this.router.navigate(['payment-credit-debit-component/' + packageid]);
    }
    if (this.eventControl.srcElement.name == 'gold') {
      let packageid = this.eventControl.srcElement.value;
      this.router.navigate(['payment-credit-debit-component/' + packageid]);
    }
  }

  goToCMSPDF(eventControl: any, url: string) {
    if (
      (eventControl.srcElement.tagName == 'A' || eventControl.srcElement.tagName == 'a') &&
      (eventControl.srcElement.getAttribute('href')?.includes('#') ||
        eventControl.srcElement.getAttribute('data-href') != null) &&
      eventControl.srcElement.className != 'loginRequired'
    ) {
      if (isPlatformBrowser(this.platformId)) {
        if (eventControl.srcElement.getAttribute('data-href') != null) {
          this.scrollViewId = eventControl.srcElement.getAttribute('data-href').split('#');
          const element = document.getElementById(String(this.scrollViewId[1]));
          element?.scrollIntoView({ behavior: 'smooth' });
          let dataurl;
          dataurl = url.split('#');
          url = dataurl[0];
          this.location.replaceState(url + '#' + this.scrollViewId[1]);
        } else {
          this.scrollViewId = eventControl.srcElement.getAttribute('href').split('#');
          const element = document.getElementById(String(this.scrollViewId[1]));
          eventControl.srcElement.setAttribute('href', 'javascript:void(0);');
          eventControl.srcElement.setAttribute('data-href', '#' + this.scrollViewId[1]);
          element?.scrollIntoView({ behavior: 'smooth' });
          let dataurl;
          dataurl = url.split('#');
          url = dataurl[0];
          this.location.replaceState(url + '#' + this.scrollViewId[1]);
        }
      } else {
        //pagenot found
      }
    } else if (
      (eventControl.srcElement.tagName == 'A' || eventControl.srcElement.tagName == 'a') &&
      eventControl.srcElement.className == 'loginRequired'
    ) {
      this.getUserDetails();
      if (this.userDetails == undefined) {
        const modalRef = this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
        modalRef.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
          if (isUserLogged) {
            modalRef.componentInstance.userid.subscribe((userid: any) => {
              if (userid) {
                this.UserId = userid;
                if (eventControl) {
                  this.pdfPath = eventControl.srcElement.getAttribute('data-href');
                  if (this.pdfPath == null || this.pdfPath == undefined || this.pdfPath == '') {
                    this.pdfPath = eventControl.target.dataset.href;
                  }
                  window.open(this.pdfPath);
                }
              }
            });
          }
        });
      } else if (this.userDetails != undefined) {
        if (eventControl) {
          this.pdfPath = eventControl.srcElement.getAttribute('data-href');

          if (this.pdfPath == null || this.pdfPath == undefined || this.pdfPath == '') {
            this.pdfPath = eventControl.target.dataset.href;
          }
          window.open(this.pdfPath);
        }
      }
    } else if (eventControl.srcElement.tagName == 'STRONG' || eventControl.srcElement.tagName == 'strong') {
      if (
        (eventControl.srcElement.parentNode.tagName == 'A' || eventControl.srcElement.parentNode.tagName == 'a') &&
        eventControl.srcElement.parentNode.className == 'loginRequired'
      ) {
        this.pdfPath = eventControl.srcElement.parentNode.getAttribute('data-href');
        if (this.pdfPath == null || this.pdfPath == undefined || this.pdfPath == '') {
          this.pdfPath = eventControl.srcElement.parentNode.target.dataset.href;
        }
        window.open(this.pdfPath);
      }
    }
  }

  getUserDetails() {
    this.userDetails = this.storage.getItem(StorageKey.USER, StorageType.LOCAL);
  }
  collapseExploreBtn(eventControl: any) {
    if (eventControl.srcElement.className == 'fa fa-angle-down') {
      let topBtn = eventControl.srcElement.parentElement.parentElement.querySelector('.explore_btn');
      let downarrow = eventControl.srcElement.parentElement.parentElement.querySelector('.click_aerrow');

      let bottomDiv = eventControl.srcElement.parentElement.parentElement.querySelector('.onExpansion');
      if (topBtn && downarrow) {
        topBtn.style.display = 'none';
        downarrow.style.display = 'none';
      }
      if (bottomDiv) {
        if (bottomDiv.offsetHeight > 0) {
          topBtn.style.display = 'block';
          downarrow.style.display = 'block';
        } else {
          bottomDiv.style.maxHeight = 'fit-content';
          bottomDiv.style.display = 'block';
        }
      }
    } else {
      let topBtn = eventControl.srcElement.parentElement.parentElement.querySelector('.explore_btn');
      let downarrow = eventControl.srcElement.parentElement.parentElement.querySelector('.click_aerrow');
      let bottomDiv = eventControl.srcElement.parentElement.parentElement.querySelector('.onExpansion');
      if (topBtn && downarrow) {
        topBtn.style.display = 'inline-block';
        downarrow.style.display = 'inline-block';
      }
      if (bottomDiv) {
        bottomDiv.style.display = 'none';
        bottomDiv.style.maxHeight = 0;
      }
    }
  }

  bookAFreeClass(userDetails: any) {
    if (!userDetails) {
      this.modalService.open(LoginComponent, { centered: true });
    } else {
      const sourceId = SourceIdEnum.JuniorHackerOrganic;
      const modalRef = this.modalService.open(FreeDemoRegistrationComponent, { centered: true, backdrop: 'static' });
      modalRef.componentInstance.UserName = this.userDetails.name;
      modalRef.componentInstance.Phone = this.userDetails.mobile;
      modalRef.componentInstance.sourceId = sourceId;
    }
  }

  validationOnContactUsPage(document: any) {
    const emailRegex = new RegExp(validationRegex.EMAIL);
    if (
      document.getElementsByName('firstname')[0].value != '' &&
      document.getElementsByName('email')[0].value != '' &&
      document.getElementsByName('Phonenum')[0] != undefined &&
      document.getElementsByName('message')[0].value != ''
    ) {
      this.ContactUs = {
        name: document.getElementsByName('firstname')[0].value,
        email: document.getElementsByName('email')[0].value,
        phoneNo: document.getElementsByName('Phonenum')[0].value,
        message: document.getElementsByName('message')[0].value,
      };
      //PHONENO
      if (document.getElementsByName('firstname')[0].value.match(validationRegex.TEXT_ONLY)?.length > 0) {
        document.getElementById('form_name_error')?.classList.remove('contact-us-non-error');
        document.getElementById('form_name_error')?.classList.add('contact-us-error');
        if (document.getElementsByName('email')[0].value.match(emailRegex)) {
          document.getElementById('form_email_error')?.classList.remove('contact-us-non-error');
          document.getElementById('form_email_error')?.classList.add('contact-us-error');
          if (
            document.getElementsByName('Phonenum')[0].value.match(validationRegex.PHONENO)?.length > 0 &&
            this.ContactUs.phoneNo?.length == 10
          ) {
            document.getElementById('form_phone_error')?.classList.remove('contact-us-non-error');
            document.getElementById('form_phone_error')?.classList.add('contact-us-error');
            this.contactService.createContactUsDetails(this.ContactUs).subscribe((message: any) => {
              if (message == 'Mail Successfully Send') {
                this.notificationService.notifyMessage(NotificationType.Success, `Thank you for contacting us.`);
                document.getElementsByName('firstname')[0].value = '';
                document.getElementsByName('email')[0].value = '';
                document.getElementsByName('Phonenum')[0].value = '';
                document.getElementsByName('message')[0].value = '';
              }
            });
          } else {
            document.getElementById('form_phone_error')?.classList.add('contact-us-non-error');
            document.getElementById('form_phone_error').innerHTML = `Please enter Valid Mobile Number.`;
            if (document.getElementsByName('email')[0].value.match(emailRegex) == null) {
              document.getElementById('form_email_error')?.classList.add('contact-us-non-error');
              document.getElementById('form_email_error').innerHTML = `Please enter Valid Email Address.`;
            }
          }
        } else {
          document.getElementById('form_email_error')?.classList.add('contact-us-non-error');
          document.getElementById('form_email_error').innerHTML = `Please enter Valid Email Address.`;
          if (
            document.getElementsByName('Phonenum')[0].value.match(validationRegex.PHONENO) == null &&
            this.ContactUs.phoneNo?.length != 10
          ) {
            document.getElementById('form_phone_error')?.classList.add('contact-us-non-error');
            document.getElementById('form_phone_error').innerHTML = `Please enter Valid Mobile Number.`;
          }
        }
      } else {
        document.getElementById('form_name_error')?.classList.add('contact-us-non-error');
        document.getElementById('form_name_error').innerHTML = `Please enter Valid Name.`;
      }
    } else {
      //document.getElementsByClassName('contact-us-name-none')[0].classList.remove('contact-us-name-none')
      this.notificationService.notifyMessage(NotificationType.Fail, `Please Enter Information!`);
    }
  }
  validationOnContactUsPageFromClientSide(contacUsData: any, document: any) {
    this.ContactUs = contacUsData;
    if (
      (this.ContactUs.name == '' || this.ContactUs.name == undefined) &&
      (this.ContactUs.email == '' || this.ContactUs.email == undefined) &&
      (this.ContactUs.phoneNo == '' || this.ContactUs.phoneNo == undefined) &&
      (this.ContactUs.message == '' || this.ContactUs.message == undefined)
    ) {
      document.getElementById('form_name_error')?.classList.add('contact-us-non-error');
      document.getElementById('form_email_error')?.classList.add('contact-us-non-error');
      document.getElementById('form_phone_error')?.classList.add('contact-us-non-error');
      document.getElementById('form_message_error')?.classList.add('contact-us-non-error');
    } else {
      this.submitParentQuey(document);
      //   if (this.ContactUs.name == '' || this.ContactUs.name == undefined) {
      //     document.getElementById('form_name_error')?.classList.remove('contact-us-error');
      //     document.getElementById('form_name_error')?.classList.add('contact-us-non-error');
      //   }
      //   else {
      //     document.getElementById('form_name_error')?.classList.remove('contact-us-non-error');
      //     document.getElementById('form_name_error')?.classList.add('contact-us-error');
      //   }
      //   if (this.ContactUs.email == '' || this.ContactUs.email == undefined) {
      //     document.getElementById('form_email_error')?.classList.remove('contact-us-error');
      //     document.getElementById('form_email_error')?.classList.add('contact-us-non-error');
      //   }
      //   else {
      //     document.getElementById('form_email_error')?.classList.remove('contact-us-non-error');
      //     document.getElementById('form_email_error')?.classList.add('contact-us-error');
      //   }
      //   if (this.ContactUs.phoneNo == '' || this.ContactUs.phoneNo == undefined) {
      //     document.getElementById('form_phoneNo_error')?.classList.remove('contact-us-error');
      //     document.getElementById('form_phoneNo_error')?.classList.add('contact-us-non-error');
      //   }
      //   else {
      //     document.getElementById('form_phoneNo_error')?.classList.remove('contact-us-non-error');
      //     document.getElementById('form_phoneNo_error')?.classList.add('contact-us-error');
      //   }
      //   if (this.ContactUs.message == '' || this.ContactUs.message == undefined) {
      //     document.getElementById('form_message_error')?.classList.remove('contact-us-error');
      //     document.getElementById('form_message_error')?.classList.add('contact-us-non-error');
      //   }
      //   else {
      //     document.getElementById('form_message_error')?.classList.remove('contact-us-non-error');
      //     document.getElementById('form_message_error')?.classList.add('contact-us-error');
      //   }
    }
  }

  submitParentQuey(document: any) {
    const submitConatctUsLead = {
      Email: 'testemail',
      Name: 'testName',
      PhoneNumber: 'phone',
      Message: 'test message',
    };
    this.bookFreeDemo.submitConatctUsLead(submitConatctUsLead).subscribe({
      next: (catData) => {
        this.responseMessage = catData;
        if (this.responseMessage == 'Mail Successfully Send') {
          this.notificationService.notifyMessage(NotificationType.Success, `Mail Successfully Send.`);
        }
      },
      error: () => {
        this.notificationService.notifyMessage(NotificationType.Fail, `Fail to Sent Mail.`);
      },
    });
  }
  loadPackageTestinomial(eventControl: any) {
    // eventControl.getElementById('big')?.classList.remove('owl-carousel');
    const test = document.getElementById('big') ?? ('' as unknown as HTMLElement);
    test.style.display = 'block';

    if ($('.add-owl-carousel').length > 0) {
      $('.add-owl-carousel').owlCarousel({
        items: 1,
        loop: true,
        margin: 10,
      });
    }
    // Select all left quotes
    const leftQuotes = document.querySelectorAll('.fa-quote-left') as NodeListOf<HTMLElement>;
    // Select all right quotes
    const rightQuotes = document.querySelectorAll('.fa-quote-right') as NodeListOf<HTMLElement>;

    // Function to apply width and height to each element
    const setQuoteSize = (elements: NodeListOf<HTMLElement>, width: string, height: string) => {
      elements.forEach((element) => {
        element.style.width = width;
        element.style.height = height;
      });
    };

    // Set the size for both left and right quotes
    setQuoteSize(leftQuotes, '24px', '24px');
    setQuoteSize(rightQuotes, '24px', '24px');
  }

  hideNRIBookNowButton() {
    if (isPlatformBrowser(this.platformId)) {
      const section = document.querySelector('.live-img-sec') as HTMLElement;
      const section2 = document.querySelector('#nriAvailNowDiv') as HTMLElement;
      const section3 = document.querySelector('.join-now-section') as HTMLElement;
      const element = document.querySelector('#textIITNRI') as HTMLElement;
      const element2 = document.querySelector('#textNEETNRI') as HTMLElement;

      if (section && section2 && section3 && (element || element2)) {
        section.style.display = 'none';
        section2.style.display = 'none';
        section3.style.display = 'none';
        if (element) {
          element.classList.remove('col-md-8');
        }
        if (element2) {
          element2.classList.remove('col-md-8');
        }
      }
      this.jsService.jsEnable();
    }
  }
}
