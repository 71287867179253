<div class="login_page completRegt">
    <div class="modal-header close_btn_align" *ngIf="isShowCrossbtn">
        <button type="button" class="btn-close  btn-close-black" data-bs-dismiss="modal"
            (click)="closeModal()"></button>
    </div>
    <div class="loader-overlay" *ngIf="isLoading">
        <div class="spinner">
            <div></div>
            <div></div>
        </div>
    </div>
    <div class="justify-content-center mb-4 pb-1 ng-star-inserted">
        <div class="user-show-head">Complete Your Profile</div>
    </div>
    <form [formGroup]="form">
        <div id="txtSearchProdAssign">
            <div class="form-group">
                <label for="name-user" class="lable-space">Phone Number</label>
                <app-country-picker name="phoneNumber" formControlName="phoneNumber"
                    [PhoneNumberwithCountryCode]="phoneNumber">
                </app-country-picker>
                <div *ngIf="f['phoneNumber'].touched && f['phoneNumber'].invalid" class="alert alert-danger">
                    <div *ngIf="f['phoneNumber'].errors && f['phoneNumber'].errors?.['required']">Phone Number is required.
                    </div>
                    <div *ngIf="f['phoneNumber'].errors && f['phoneNumber'].errors?.['invalidPhoneNumber']" class="text-danger">Invalid Phone Number.</div>
                </div>
            </div>
            <div class="form-group">
                <label for="name-user" class="lable-space">Name</label>
                <input type="text" id="names-user" placeholder="Type Name Here..." required
                    class="form-control input-text" name="name" formControlName="name" />
                <div *ngIf="f['name'].touched && f['name'].invalid" class="alert alert-danger">
                    <div *ngIf="f['name'].errors && f['name'].errors['required']">Please Enter Name.
                    </div>
                </div>

            </div>
            <div class="form-group">
                <label for="name-user" class="lable-space">Email</label>
                <input type="email" id="email-user" placeholder="Type Email Here..." required
                    class="form-control input-text" name="email" formControlName="email" />
                <div *ngIf="f['email'].touched && f['email'].invalid" class="alert alert-danger">
                    <div *ngIf="f['email'].errors && f['email'].errors['required']">Email is
                        required.</div>
                    <div *ngIf="f['email'].errors &&  f['email'].errors['pattern']" class="text-danger">
                        Please provide a valid email address</div>
                </div>
            </div>
            <div class="form-group row justify-content-around ">
                <div class="select-grade col-12 px-3">
                    Select grade of the Student
                </div>
                <div class="col-md-12 col-4 mb-2 gradeSelection garde-padding mx-3"
                    *ngFor="let items of getGrade| orderBy:'asc':'id'">
                    <div class="garde-padding">
                        <input type="radio" name="gradeId" id="{{items.id}}" value="{{items.id}}"
                            class="form-check-input form-control" required formControlName="gradeId" />
                        <label class="radio-label" for="{{items.name}}">{{items.name}}<sup>th</sup></label>
                    </div>
                </div>
            </div>
            <div *ngIf="f['gradeId'].touched && f['gradeId'].invalid" class="alert alert-danger selectGrade">
                <div *ngIf="f['gradeId'].errors && f['gradeId'].errors['required']">Please Select the Grade
                </div>
            </div>
            <div class="mt-4 text-center login_submit">
                <button type="submit" class="btn-cms-valid" (click)="validateUser()">Submit</button>
            </div>
        </div>
    </form>
</div>